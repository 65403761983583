import { Box, Flex } from '@chakra-ui/react';
import { Draggable, Droppable } from 'react-beautiful-dnd';
import { useState } from 'react';
import { LmmJobResponse } from '../../shared/entity.ts';
import PlanCard from './PlanCard.tsx';

export default function PlanBacklog({
  jobs,
  setJobInEdit,
  setJobToDelete,
}: {
  jobs: LmmJobResponse[];
  setJobInEdit: (job: LmmJobResponse) => void;
  setJobToDelete: (job: LmmJobResponse) => void;
}) {
  const [isDraggingOver, setIsDraggingOver] = useState(false);

  return (
    <Droppable droppableId="backlog" direction="horizontal">
      {(provided, snapshot) => {
        setIsDraggingOver(snapshot.isDraggingOver);
        return (
          <Box
            ref={provided.innerRef}
            {...provided.droppableProps}
            width="100%"
            padding="16px"
            borderRadius="8px"
            backgroundColor={isDraggingOver ? '#3888ff' : '#2c2c2c'}
          >
            <Flex direction="row" wrap="nowrap" gap="16px" sx={{ overflowX: 'auto' }}>
              {jobs.map((job: LmmJobResponse) => (
                <Draggable key={job.id} draggableId={job.id} index={0}>
                  {(provided, snapshot) => (
                    <div ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                      <Box width="320px">
                        <PlanCard
                          key={job.id}
                          job={job}
                          setJobInEdit={setJobInEdit}
                          setJobToDelete={setJobToDelete}
                        ></PlanCard>
                      </Box>
                    </div>
                  )}
                </Draggable>
              ))}
            </Flex>
          </Box>
        );
      }}
    </Droppable>
  );
}
