import { Outlet } from 'react-router-dom';
import { useUser } from '../hook/useUser.ts';

export function MainLayout() {
  const { isAuthenticated } = useUser();

  return (
    <div style={{ display: 'flex', width: '100%', height: '100%' }}>
      <main style={{ width: '100%', height: '100%' }}>
        <Outlet />
      </main>
    </div>
  );
}
