export function round(val: number, decimalPlaces?: boolean) {
  if (val == undefined) {
    return undefined;
  }
  if (decimalPlaces) {
    return Math.round(val * 100) / 100;
  }
  let output = Math.round(val);
  if(output == 0 && val > 0) {
    output = 1;
  }
  return output;
}

export function percent(val: number, totalCount: number) {
  if (val == undefined || val == null) {
    return 0;
  }
  val = (val / totalCount) * 100;
  return round(val);
}

export function adjustCentermile(val: number) {
  if (val == undefined) {
    return undefined;
  }
  if (val >= 1) {
    return Math.round((val + Number.EPSILON) * 10) / 10;
  } else {
    return Math.round((val + Number.EPSILON) * 100) / 100;
  }
}
