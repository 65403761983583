/* eslint-disable complexity */
import { createContext, useReducer, ReactNode, Dispatch } from 'react';
import { LmmImage, LmmJobResponse, LmmMap, LmmRoad, LmmSection, Treatment, UserResponse } from '../shared/entity.ts';
import { JobCategory } from '../shared/const.ts';
import DISPATCH_ACTIONS from './actions.ts';

interface State {
  lmmImages: LmmImage[];
  imagesInView: LmmImage[];
  markersByRoad: { [key: string]: google.maps.marker.AdvancedMarkerElement[] };
  lmmRoads: LmmRoad[];
  lmmSections: LmmSection[];
  treatments: Treatment[];
  companyUsers: UserResponse[];
  mapBounds: { south: number; west: number; north: number; east: number };
  isCreatingJob: boolean;
  isEditingPlanMapViewJob: boolean;
  roadToSelectMilePost: LmmRoad;
  mapData: LmmMap;
  startEndMilePost: { start: google.maps.LatLng; end: google.maps.LatLng; polyPath?: google.maps.LatLng[] };
  topNavPlaceholder: {
    centerline: number;
    miles: number;
    averagePci: number;
    mapId: string;
    pci: {
      excellent: number;
      good: number;
      fair: number;
      poor: number;
    };
  };
  currentLineData: {
    centerline: number;
    lane: number;
  };
  backLogJobs: LmmJobResponse[];
  maintenanceJobs: LmmJobResponse[];
  currentRoadJobs: LmmJobResponse[];
}

const initialState: State = {
  lmmImages: [],
  lmmRoads: [],
  imagesInView: [],
  markersByRoad: {},
  lmmSections: [],
  treatments: [],
  companyUsers: [],
  isCreatingJob: false,
  isEditingPlanMapViewJob: false,
  mapData: null,
  roadToSelectMilePost: null,
  startEndMilePost: { start: null, end: null },
  mapBounds: { south: 0, west: 0, north: 0, east: 0 },
  topNavPlaceholder: {
    centerline: 0,
    miles: 0,
    averagePci: 0,
    mapId: null,
    pci: {
      excellent: 0,
      good: 0,
      fair: 0,
      poor: 0,
    },
  },
  currentLineData: {
    centerline: 0,
    lane: 0,
  },
  backLogJobs: [],
  maintenanceJobs: [],
  currentRoadJobs: [],
};

interface Action {
  type: string;
  payload: any;
}

export const GlobalContext = createContext<{
  state: State;
  dispatch: Dispatch<Action>;
}>({
  state: initialState,
  dispatch: () => undefined,
});

const reducer = (state: State, action: Action): State => {
  switch (action.type) {
    case DISPATCH_ACTIONS.SET_LMMIMAGES:
      return {
        ...state,
        lmmImages: action.payload,
      };
    case DISPATCH_ACTIONS.SET_MARKERS_BY_ROAD: {
      const markersByRoad = { ...state.markersByRoad };
      markersByRoad[action.payload.roadId] = action.payload.markers;
      return {
        ...state,
        markersByRoad,
      };
    }
    case DISPATCH_ACTIONS.SET_LMMROADS:
      return {
        ...state,
        lmmRoads: action.payload,
      };
    case DISPATCH_ACTIONS.SET_TREATMENTS:
      return {
        ...state,
        treatments: action.payload,
      };
    case DISPATCH_ACTIONS.SET_COMPANY_USERS:
      return {
        ...state,
        companyUsers: action.payload,
      };
    case DISPATCH_ACTIONS.SET_CURRENT_ROAD_JOBS:
      return {
        ...state,
        currentRoadJobs: action.payload,
      };
    case DISPATCH_ACTIONS.SET_IMAGESINVIEW:
      console.log('imagesInView22', action.payload);
      return {
        ...state,
        imagesInView: action.payload,
      };
    case DISPATCH_ACTIONS.SET_LMMSECTIONS:
      return {
        ...state,
        lmmSections: action.payload,
      };
    case DISPATCH_ACTIONS.SET_MAP_BOUNDS: {
      const newState = {
        ...state,
        mapBounds: { ...action.payload },
      };
      return newState;
    }
    case DISPATCH_ACTIONS.SET_TOPNAVPLACEHOLDAER:
      if (action.payload) {
        localStorage.setItem('pciSegment', JSON.stringify(action.payload));
      }
      return {
        ...state,
        topNavPlaceholder: action.payload,
      };
    case DISPATCH_ACTIONS.SET_LMMJOBS:
      if (action.payload?.category == JobCategory.backlog) {
        return {
          ...state,
          backLogJobs: action.payload?.jobs,
        };
      } else {
        return {
          ...state,
          maintenanceJobs: action.payload?.jobs,
        };
      }
    case DISPATCH_ACTIONS.SET_IS_CREATING_JOB:
      return {
        ...state,
        isCreatingJob: action.payload,
      };
    case DISPATCH_ACTIONS.SET_MAP_DATA:
      return {
        ...state,
        mapData: action.payload,
      };
    case DISPATCH_ACTIONS.SET_IS_UPDATING_PLAN_MAP_JOB:
      return {
        ...state,
        isEditingPlanMapViewJob: action.payload,
      };
    case DISPATCH_ACTIONS.SET_ROAD_TO_SELECT_MILEPOST:
      return {
        ...state,
        roadToSelectMilePost: action.payload,
      };

    case DISPATCH_ACTIONS.SET_MILEPOST:
      return {
        ...state,
        startEndMilePost: { ...state.startEndMilePost, ...action.payload },
      };
    case DISPATCH_ACTIONS.SET_CURRENTLINE_DATA:
      return {
        ...state,
        currentLineData: { ...state.currentLineData, ...action.payload },
      };
    case DISPATCH_ACTIONS.UPDATE_JOB_DATA: {
      if (action.payload?.category == JobCategory.backlog) {
        const bJobs = [...state.backLogJobs];
        const index = bJobs.findIndex((job) => job.id === action.payload.job.id);
        if (index !== -1) {
          bJobs.splice(index, 1, action.payload.job);
        }
        return {
          ...state,
          backLogJobs: bJobs,
        };
      } else {
        const mJobs = [...state.maintenanceJobs];
        const index = mJobs.findIndex((job) => job.id === action.payload.job.id);
        if (index !== -1) {
          mJobs.splice(index, 1, action.payload.job);
        }
        return {
          ...state,
          maintenanceJobs: mJobs,
        };
      }
    }
    default:
      return state;
  }
};

interface GlobalProviderProps {
  children: ReactNode;
}

// eslint-disable-next-line react/prop-types
const GlobalProvider: React.FC<GlobalProviderProps> = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  return <GlobalContext.Provider value={{ state, dispatch }}>{children}</GlobalContext.Provider>;
};

export default GlobalProvider;
