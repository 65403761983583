import { Box } from '@chakra-ui/react';

export default function JobsBadge({ numJobs }: { numJobs: number }) {
  return (
    <Box
      color="#3888ff"
      backgroundColor="rgba(56, 136, 255, 0.15)"
      fontSize="14px"
      fontWeight="500"
      padding="4px 12px"
      borderRadius="50px"
      marginLeft="16px"
    >
      {`${numJobs} Job${numJobs > 1 ? 's' : ''}`}
    </Box>
  );
}
