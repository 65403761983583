import { Box, Flex, Text } from '@chakra-ui/react';
import { useMemo, useState } from 'react';
import { Draggable, Droppable } from 'react-beautiful-dnd';
import { LmmJobResponse } from '../../shared/entity.ts';
import { JobStatus } from '../../shared/const.ts';
import { formatAsShortenedCurrency } from '../../utils/stringUtils.tsx';
import JobsBadge from './JobsBadge.tsx';
import PlanCard from './PlanCard.tsx';

function calculateCostRatio(jobs: LmmJobResponse[]): { spent: number; budget: number } {
  const costRatio = { spent: 0, budget: 0 };
  for (const job of jobs) {
    if (job.status != JobStatus.not_started) {
      costRatio.spent += job.estimatedCost;
    }
    costRatio.budget += job.estimatedCost;
  }
  return costRatio;
}

export default function PlanYear({
  name,
  year,
  jobs,
  setJobInEdit,
  setJobToDelete,
}: {
  name: string;
  year: number;
  jobs: LmmJobResponse[];
  setJobInEdit: (job: LmmJobResponse) => void;
  setJobToDelete: (job: LmmJobResponse) => void;
}) {
  const [isDraggingOver, setIsDraggingOver] = useState(false);
  const costRatio = useMemo(() => calculateCostRatio(jobs), [jobs]);

  return (
    <Box
      width="360px"
      height="100%"
      padding="16px"
      border="1px solid #383839"
      borderRadius="8px"
      backgroundColor={isDraggingOver ? '#3888ff' : '#2c2c2c'}
    >
      <Droppable droppableId={`year:${year}`}>
        {(provided, snapshot) => {
          setIsDraggingOver(snapshot.isDraggingOver);
          return (
            <Flex height="100%" flexDirection="column" ref={provided.innerRef} {...provided.droppableProps}>
              <Flex marginBottom="16px">
                <Text noOfLines={1} fontWeight="bold" fontSize="18px">
                  {name}
                </Text>
                <JobsBadge numJobs={jobs.length}></JobsBadge>
                <Text flex="none" marginLeft="auto">
                  {formatAsShortenedCurrency(costRatio.spent, /*returnZero=*/ true)}
                  <span style={{ color: '#979BA6' }}>
                    {' '}
                    / {formatAsShortenedCurrency(costRatio.budget, /*returnZero=*/ true)}
                  </span>
                </Text>
              </Flex>
              <Flex overflowY="scroll" gap="16px" direction="column">
                {provided.placeholder}
                {jobs.map((job) => (
                  <Draggable key={job.id} draggableId={job.id} index={0}>
                    {(provided, snapshot) => (
                      <div ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                        <PlanCard
                          key={job.id}
                          job={job}
                          setJobInEdit={setJobInEdit}
                          setJobToDelete={setJobToDelete}
                        ></PlanCard>
                      </div>
                    )}
                  </Draggable>
                ))}
              </Flex>
            </Flex>
          );
        }}
      </Droppable>
    </Box>
  );
}
