import { useCallback } from 'react';
import type { Geometry } from 'geojson';
import { BoundsObject } from '../types/mapTypes.ts';
import { UseRecenterParams } from './interface.ts';

export const useMapRecenter = ({ mapRef, markersRef }: UseRecenterParams) => {
  return useCallback(
    (
      reference:
        | Geometry
        | google.maps.LatLng
        | google.maps.Data.Geometry
        | google.maps.Data
        | BoundsObject
        | readonly google.maps.marker.AdvancedMarkerElement[] = markersRef?.current,
      zoomLevel?: number
    ) => {
      if (!mapRef.current) return;
      if (zoomLevel && reference instanceof google.maps.LatLng) {
        mapRef.current.setCenter(reference);
        mapRef.current.setZoom(zoomLevel);
      } else {
        if (reference instanceof google.maps.LatLng) {
          mapRef.current.setCenter(reference);
          return;
        }

        const bounds = new google.maps.LatLngBounds();

        if (reference && (reference as BoundsObject).south) {
          const mapBounds = reference as BoundsObject;
          bounds.extend({ lat: mapBounds.south, lng: mapBounds.west });
          bounds.extend({ lat: mapBounds.north, lng: mapBounds.east });
          mapRef.current.fitBounds(bounds);
          return;
        }
        if (reference instanceof google.maps.Data) {
          mapRef.current.data.forEach((feature) => feature.getGeometry().forEachLatLng((g) => bounds.extend(g)));
        } else if (Array.isArray(reference) && reference.length > 0) {
          (reference as google.maps.marker.AdvancedMarkerElement[]).forEach(({ position }) => bounds.extend(position));
        } else if (reference) {
          (reference as google.maps.Data.Geometry).forEachLatLng((latLng) => bounds.extend(latLng));
        }
        mapRef.current.fitBounds(bounds);
      }
    },
    [mapRef, markersRef]
  );
};
