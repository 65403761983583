import { useQuery } from 'react-query';
import { useContext } from 'react';
import { City, LmmMap, LmmRoad, Treatment, UserResponse } from '../shared/entity.ts';
import { GlobalContext } from '../context/GlobalContext.tsx';
import DISPATCH_ACTIONS from '../context/actions.ts';

export const GetTenantMap = () => {
  const { dispatch } = useContext(GlobalContext);
  return useQuery<LmmMap>('/api/maps/one', {
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    refetchOnMount: false,
    onSuccess(map) {
      localStorage.setItem('mapData', JSON.stringify(map));
      dispatch({ type: DISPATCH_ACTIONS.SET_MAP_DATA, payload: map });
    },
  });
};

export const GetMapBounds = () => {
  const { dispatch } = useContext(GlobalContext);
  return useQuery<{ south: number; west: number; north: number; east: number }>('/api/maps/bounds', {
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    refetchOnMount: true,
    onSuccess(bounds) {
      dispatch({ type: DISPATCH_ACTIONS.SET_MAP_BOUNDS, payload: bounds });
    },
  });
};

export const GetLmmRoads = () => {
  const { dispatch } = useContext(GlobalContext);
  return useQuery<LmmRoad[]>('/api/roads', {
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    refetchOnMount: true,
    onSuccess(roads) {
      dispatch({ type: DISPATCH_ACTIONS.SET_LMMROADS, payload: roads });
    },
  });
};

export const GetPciReport = () => {
  const { dispatch } = useContext(GlobalContext);
  return useQuery<{
    pciCount: {
      excellent: number;
      good: number;
      fair: number;
      poor: number;
      very_poor: number;
      total: number;
    };
    average: number;
  }>('/api/images/pciReport', {
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    refetchOnMount: true,
    onSuccess(pciReport) {
      console.log('result2', pciReport);
      // dispatch({ type: DISPATCH_ACTIONS.SET_LMMROADS, payload: roads });
    },
  });
};

export const GetCities = () => {
  return useQuery<City[]>('/api/cities', {
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    refetchOnMount: false,
  });
};

export const GetTreaments = () => {
  const { dispatch } = useContext(GlobalContext);
  return useQuery<Treatment[]>('/api/treatments', {
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    refetchOnMount: false,
    onSuccess(treatments) {
      dispatch({ type: DISPATCH_ACTIONS.SET_TREATMENTS, payload: treatments });
    },
  });
};

export const GetCompanyUsers = () => {
  const { dispatch } = useContext(GlobalContext);
  return useQuery<UserResponse[]>('/api/company/users', {
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    refetchOnMount: false,
    onSuccess(users) {
      dispatch({ type: DISPATCH_ACTIONS.SET_COMPANY_USERS, payload: users });
    },
  });
};
