import { Input } from '@chakra-ui/react';
import { useCallback, useEffect, useRef } from 'react';
import { ComponentStyle } from '../interface.tsx';
import { LmmImage } from '../shared/entity.ts';
import { GetMapBounds } from '../query/mapqueries.ts';

export function MapSearch({
  recenter,
  images,
}: {
  recenter: (position?: google.maps.LatLng) => void;
  images: LmmImage[];
}) {
  const autocompleteRef = useRef<google.maps.places.Autocomplete>(null);
  const inputRef = useRef<HTMLInputElement>(null);

  const { data } = GetMapBounds();

  const onPlaceChanged = useCallback(() => {
    const place = autocompleteRef.current.getPlace();
    if (!place) {
      return;
    }
    if (place.geometry?.location) {
      recenter(place?.geometry.location);
    } else if (place?.name) {
      const val = place?.name.trim().replace(/,\s+/, ',');
      const rCoordinate = /^-?([1-8]?\d(\.\d+)?|90(\.0+)?)$/;
      const [lat, lng] = val.split(/\s+|,+/);
      if (rCoordinate.test(lat) && rCoordinate.test(lng)) {
        const latLng = new google.maps.LatLng({ lat: Number(lat), lng: Number(lng) });
        recenter(latLng);
      }
    }
  }, [recenter]);

  useEffect(() => {
    const boundsData = data;
    if (boundsData) {
      const bounds = new google.maps.LatLngBounds(
        new google.maps.LatLng(boundsData.south, boundsData.west),
        new google.maps.LatLng(boundsData.north, boundsData.east)
      );
      const init = async () => {
        const { Autocomplete } = (await google.maps.importLibrary('places')) as google.maps.PlacesLibrary;
        autocompleteRef.current = new Autocomplete(inputRef.current, {
          fields: ['geometry'],
          bounds,
          strictBounds: true,
        });
        autocompleteRef.current.addListener('place_changed', onPlaceChanged);
      };
      init();
    }
  }, [data, images, onPlaceChanged]);

  return (
    <div style={styles.container}>
      {data?.south && (
        <>
          <span className="material-icons" style={{ color: 'var(--border-color-lighter)' }}>
            search
          </span>
          <Input
            ref={inputRef}
            placeholder="Search InfraHub"
            _placeholder={{ fontSize: '18px', color: 'var(--border-color-lighter)' }}
            focusBorderColor="transparent"
            size="sm"
            width="18rem"
            border="none"
            title="Enter the search term and hit enter"
            className="mapSearchInput"
          />
        </>
      )}
    </div>
  );
}

const styles: ComponentStyle = {
  container: {
    padding: '0.4rem 1.0rem',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginLeft: 'auto',
    borderLeft: '1px solid var(--separator-color)',
  },
};
