import {
  Box,
  Button,
  Stepper,
  Step,
  StepIndicator,
  StepStatus,
  StepSeparator,
  useSteps,
  Select,
  Spinner,
  useToast,
  IconButton,
} from '@chakra-ui/react';
import { useContext, useEffect, useState } from 'react';
import { useScreenDimensions } from '../../shared/hook/useScreenDimensions.ts';
import { ComponentStyle } from '../../interface.tsx';
import AppInput from '../../shared/component/AppInput.tsx';
import { MyIcon } from '../../shared/component/Icons.tsx';
import { GetCompanyUsers, GetLmmRoads, GetTreaments } from '../../query/mapqueries.ts';
import SearchableInput from '../../shared/component/SearchableInput.tsx';
import { JobCategory, JobStatus } from '../../shared/const.ts';
import { formatAsCurrency, removeAllCommas, textIsNumeric } from '../../utils/stringUtils.tsx';
import { LmmJobResponse, LmmRoad } from '../../shared/entity.ts';
import { APP_COLORS } from '../../utils/constants.ts';
import { InputItem } from '../../shared/hook/interface.ts';
import SearchableListInput from '../../shared/component/SearchableListInput.tsx';
import { GlobalContext } from '../../context/GlobalContext.tsx';
import DISPATCH_ACTIONS from '../../context/actions.ts';

export function CreateJobPanel({
  onClose,
  onCreateSuccess,
  job,
  road,
  onSelectMilePost,
  isCentered,
  onChangeRoad,
}: {
  onClose(): void;
  onCreateSuccess(): void;
  job?: LmmJobResponse;
  road?: LmmRoad;
  onSelectMilePost?: (param: any) => void;
  isCentered?: boolean;
  onChangeRoad?: (param: LmmRoad) => void;
}) {
  const { dispatch, state } = useContext(GlobalContext);
  const { height } = useScreenDimensions();
  const toast = useToast();
  const [isShrunk, setIsShrunk] = useState(false);
  const [previouslySelectedRoad, setPreviouslySelectedRoad] = useState('');
  const [selectingMilePost, setSelectingMilePost] = useState<'save' | 'cancel' | 'reset' | 'edit'>(null);
  const { data: roadsRef = [] } = GetLmmRoads();

  const { data: treatments = [] } = GetTreaments();
  const { data: companyUsers = [] } = GetCompanyUsers();
  const [isSubmitting, setIsSubmiting] = useState<boolean>(false);

  const { activeStep, setActiveStep, goToNext } = useSteps({
    index: 0,
    count: 3,
  });
  const [jobType, setJobType] = useState<string>();
  const initialFormState = {
    name: '',
    treatment: '',
    estimatedCost: '',
    startingMilepost: '',
    endingMilepost: '',
    plannedDate: '',
    category: JobCategory.backlog as string,
    status: JobStatus.not_started as string,
    ownerId: '',
  };
  const [form, setForm] = useState(initialFormState);

  useEffect(() => {
    dispatch({ type: DISPATCH_ACTIONS.SET_MILEPOST, payload: { start: null, end: null, polyPath: null } });
    dispatch({ type: DISPATCH_ACTIONS.SET_ROAD_TO_SELECT_MILEPOST, payload: null });
  }, [dispatch]);

  const onChangeJobType = (value: string) => {
    if (activeStep < 1) {
      goToNext();
    }
    setJobType(value);
  };

  useEffect(() => {
    if (job) {
      setForm({
        name: job.roadName,
        treatment: job.treatmentName,
        estimatedCost: formatAsCurrency(`${job.estimatedCost}`),
        plannedDate: job.plannedDate,
        startingMilepost: job.startingMilepost,
        endingMilepost: job.endingMilepost,
        category: job.category,
        status: job.status,
        ownerId: job.ownerId,
      });
      setActiveStep(3);
    } else {
      setForm(initialFormState);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [job, setActiveStep]);

  useEffect(() => {
    if (road) {
      setTimeout(() => {
        setForm({ ...form, name: road.name });
      }, 500);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [road]);

  type FormKeys =
    | 'name'
    | 'treatment'
    | 'estimatedCost'
    | 'plannedDate'
    | 'category'
    | 'status'
    | 'startingMilepost'
    | 'endingMilepost'
    | 'ownerId';

  const updateFormValue = (key: FormKeys, value: string | InputItem) => {
    const formCopy = { ...form };
    if (typeof value == 'string') {
      if (key == 'estimatedCost') {
        if (!textIsNumeric(value as string)) return;
        formCopy.estimatedCost = formatAsCurrency(value);
      } else {
        formCopy[key] = value;
      }
    } else if (key == 'ownerId') {
      formCopy.ownerId = companyUsers.find((x) => x.id == value.id)?.id;
    }

    setForm(formCopy);
  };
  useEffect(() => {
    if (form.startingMilepost.length && form.endingMilepost.length && form.name.length) {
      setActiveStep(1);
      if (form.estimatedCost.length && form.treatment.length) {
        setActiveStep(2);
        if (form.ownerId.length && form.category.length && form.status.length) {
          setActiveStep(3);
        }
      }
    }
  }, [form, setActiveStep]);

  const handleCreateJob = () => {
    const selectedRoad = roadsRef.find((x) => x.name == form.name);
    if (!selectedRoad) {
      toast({
        title: 'Please select a valid road',
        status: 'error',
      });
      return;
    }
    const selectedTreatment = treatments.find((x) => x.name == form.treatment);
    if (!selectedTreatment) {
      toast({
        title: 'Please select a valid treatment',
        status: 'error',
      });
      return;
    }
    const body = {
      plannedDate: form.plannedDate,
      estimatedCost: parseFloat(removeAllCommas(form.estimatedCost)),
      roadId: selectedRoad.id,
      treatmentId: selectedTreatment.id,
      status: form.status,
      category: form.category,
      startingMilepost: form.startingMilepost,
      endingMilepost: form.endingMilepost,
      ownerId: form.ownerId,
    };
    setIsSubmiting(true);
    fetch(`/api/jobs`, {
      body: JSON.stringify(body),
      method: 'post',
      headers: { 'content-type': 'application/json' },
    })
      .then((data) => {
        setIsSubmiting(false);
        toast({
          title: 'Job created successfully',
          status: 'success',
        });
        onCreateJobSuccess();
      })
      .catch((err) => {
        setIsSubmiting(false);
        toast({
          title: 'Ops, job creation failed',
          status: 'error',
        });
      });
  };

  const handleUpdateJob = () => {
    const selectedRoad = roadsRef.find((x) => x.name == form.name);
    if (!selectedRoad) {
      toast({
        title: 'Please select a valid road',
        status: 'error',
      });
      return;
    }
    const selectedTreatment = treatments.find((x) => x.name == form.treatment);
    if (!selectedTreatment) {
      toast({
        title: 'Please select a valid treatment',
        status: 'error',
      });
      return;
    }
    const body = {
      plannedDate: form.plannedDate,
      estimatedCost: parseFloat(removeAllCommas(form.estimatedCost)),
      roadId: selectedRoad.id,
      treatmentId: selectedTreatment.id,
      status: form.status,
      category: form.category,
      startingMilepost: form.startingMilepost,
      endingMilepost: form.endingMilepost,
    };
    setIsSubmiting(true);
    fetch(`/api/jobs/${job.id}`, {
      body: JSON.stringify(body),
      method: 'put',
      headers: { 'content-type': 'application/json' },
    })
      .then((data) => {
        setIsSubmiting(false);
        toast({
          title: 'Job updated successfully',
          status: 'success',
        });
        onCreateJobSuccess();
      })
      .catch((err) => {
        setIsSubmiting(false);
        toast({
          title: 'Ops, job creation failed',
          status: 'error',
        });
      });
  };

  const onCreateJobSuccess = () => {
    setForm(initialFormState);
    dispatch({ type: DISPATCH_ACTIONS.SET_ROAD_TO_SELECT_MILEPOST, payload: null });
    dispatch({ type: DISPATCH_ACTIONS.SET_MILEPOST, payload: { start: null, end: null } });
    setActiveStep(0);
    dispatch({ type: DISPATCH_ACTIONS.SET_IS_CREATING_JOB, payload: false });
    onCreateSuccess();
    setSelectingMilePost(null);
    onSelectMilePost(false);
    onClose();
  };

  useEffect(() => {
    setForm({
      ...form,
      startingMilepost: state.startEndMilePost.start?.toString() || '',
      endingMilepost: state.startEndMilePost.end?.toString() || '',
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.startEndMilePost]);

  const onHandleClose = () => {
    onCreateJobSuccess();
  };

  const handleShrink = () => {
    setIsShrunk((prev) => !prev);
  };

  const handleSetMilePost = (state: 'save' | 'cancel' | 'reset' | 'edit') => {
    if (state == 'edit') {
      let roadToSet = road;
      if (!roadToSet) {
        roadToSet = roadsRef.find((x) => x.name == form.name);
      }
      if (!roadToSet) {
        toast({
          title: 'Please select a road to set mile post',
          status: 'error',
        });
        return;
      }
      dispatch({ type: DISPATCH_ACTIONS.SET_ROAD_TO_SELECT_MILEPOST, payload: roadToSet });
      setSelectingMilePost(state);
      onSelectMilePost(state);
    } else if (state == 'cancel') {
      dispatch({ type: DISPATCH_ACTIONS.SET_ROAD_TO_SELECT_MILEPOST, payload: null });
      const formCopy = { ...form };
      formCopy.startingMilepost = initialFormState.startingMilepost;
      formCopy.endingMilepost = initialFormState.endingMilepost;
      setForm(formCopy);
      setSelectingMilePost(null);
      onSelectMilePost(null);
    } else {
      setSelectingMilePost(null);
      onSelectMilePost(null);
      dispatch({ type: DISPATCH_ACTIONS.SET_ROAD_TO_SELECT_MILEPOST, payload: null });
    }
  };

  const onChangeRoadAction = (value: string) => {
    if (onChangeRoad && value != previouslySelectedRoad) {
      setPreviouslySelectedRoad(value);
      const roadSelected = roadsRef.find((x) => x.name == value);
      onChangeRoad(roadSelected);
    }
  };

  return (
    <>
      {false && (
        <Box
          bg="var(--bg-color)"
          sx={{
            width: '360px',
            maxWidth: '500px',
            top: road ? '46px' : '20px',
            borderRadius: '24px',
            ...(road && { marginRight: 'auto', marginLeft: '24px' }),
            ...styles.container,
          }}
        >
          <Box
            display="flex"
            flexDirection="row"
            justifyContent="space-between"
            alignItems="center"
            py="10px"
            px="15px"
          >
            <p style={{ fontSize: '18px', fontWeight: '500' }}>Select Start/End Post</p>
            <Box>
              <IconButton
                title="Done"
                aria-label="Done"
                icon={<MyIcon icon="check" style={{ color: APP_COLORS.green }} />}
                size="md"
                onClick={() => handleSetMilePost(null)}
                sx={{ background: 'none' }}
              />
              <IconButton
                title="Reset"
                aria-label="Reset"
                icon={<MyIcon icon="restart_alt" style={{ color: APP_COLORS.yellow }} />}
                size="md"
                onClick={() => handleSetMilePost('reset')}
                sx={{ background: 'none' }}
              />
              <IconButton
                title="Close"
                aria-label="Close"
                icon={<MyIcon icon="close" style={{ color: '#979BA6' }} />}
                size="md"
                onClick={() => handleSetMilePost(null)}
                sx={{ background: 'none' }}
              />
            </Box>
          </Box>
        </Box>
      )}
      {/* {!selectingMilePost && ( */}
      <Box
        bg="var(--bg-color)"
        sx={{
          width: isShrunk ? '220px' : '30%',
          maxWidth: '500px',
          top: road ? '46px' : '20px',
          borderRadius: '24px',
          ...(road && { marginRight: 'auto', marginLeft: '24px' }),
          ...styles.container,
          height: isShrunk ? '60px' : `${height - 130}px`,
          overflowY: 'scroll',
          ...(isCentered && { marginLeft: 'auto', marginRight: 'auto', position: 'relative' }),
        }}
      >
        {/* {isShrunk && !selectingMilePost && (
            <Box
              display="flex"
              flexDirection="row"
              justifyContent="space-between"
              alignItems="center"
              py="10px"
              px="15px"
            >
              <p style={{ fontSize: '20px', fontWeight: '500' }}>Create Job</p>
              <Box>
                <IconButton
                  title="Maximize"
                  aria-label="Maximize"
                  icon={<MyIcon icon="fullscreen" style={{ color: '#979BA6', fontSize: '20px' }} />}
                  size="md"
                  onClick={handleShrink}
                  sx={{ background: 'none' }}
                />
                <IconButton
                  title="Close"
                  aria-label="Close"
                  icon={<MyIcon icon="close" style={{ color: '#979BA6' }} />}
                  size="md"
                  onClick={onHandleClose}
                  sx={{ background: 'none' }}
                />
              </Box>
            </Box>
          )} */}

        {/* {!isShrunk && !selectingMilePost && ( */}
        <Box px="32px" pt="0px">
          {/* <Box position="relative" width="100%" margin="auto"> */}
          <Box
            mx="0px"
            px="0px"
            pt="15px"
            top="10px"
            mb="20px"
            position="sticky"
            zIndex="1"
            backgroundColor="var(--bg-color)"
            // minWidth="360px"
            // maxWidth="500px"
            width="100%"
          >
            <Box
              display="flex"
              flexDirection="row"
              justifyContent="space-between"
              alignItems="center"
              // paddingTop="30px"
            >
              <p style={{ fontSize: '24px', fontWeight: '700' }}>Create Job</p>
              <Box>
                {/* <IconButton
                  title="Minimize"
                  aria-label="Minimize"
                  icon={<MyIcon icon="fullscreen_exit" style={{ color: '#979BA6' }} />}
                  size="md"
                  onClick={handleShrink}
                  sx={{ background: 'none' }}
                /> */}
                <IconButton
                  title="Close"
                  aria-label="Close"
                  icon={<MyIcon icon="close" style={{ color: '#979BA6', fontSize: '30px' }} />}
                  size="md"
                  onClick={onHandleClose}
                  sx={{ background: 'none', marginLeft: '5px' }}
                />
              </Box>
            </Box>
            <Box backgroundColor="var(--separator-color)" height="1px" width="100%" mt="10px" mb="8px"></Box>
          </Box>
          {/* </Box> */}

          {/* <div style={{ width: '100%', height: '80px' }}></div> */}
          <Stepper index={activeStep} orientation="vertical" colorScheme="customBlue" gap="0">
            {[{}, {}, {}].map((step, index) => (
              <Step key={index} style={{ width: '100%' }}>
                <StepIndicator marginRight="24px" borderColor="var(--border-color-lighter)">
                  <StepStatus complete={<MyIcon icon="radio_button_unchecked" />} incomplete={<></>} active={<></>} />
                </StepIndicator>

                {/* {index == 0 && (
                    <Box mb="32px">
                      <p style={styles.label}>Job Type</p>
                      <RadioGroup onChange={onChangeJobType} value={jobType}>
                        <Stack direction="row">
                          <Radio value="1" size="lg" colorScheme="customBlue">
                            <span style={styles.radioLabel}>Pavement</span>
                          </Radio>
                          <Radio value="2" size="lg" colorScheme="customBlue" color="#fff">
                            <span style={styles.radioLabel}>Roadside Asset</span>
                          </Radio>
                        </Stack>
                      </RadioGroup>
                    </Box>
                  )} */}

                {index == 0 && (
                  <Box mb="32px" width="100%">
                    <p style={styles.label}>Route Details</p>
                    <SearchableInput
                      list={roadsRef}
                      id="roadName"
                      readonly={!!road}
                      label="Road Name"
                      value={form.name}
                      labelStyles={styles.inputLabel}
                      selectOptionAction={(value) => {
                        setActiveStep(1);
                        updateFormValue('name', value);
                        onChangeRoadAction(value);
                      }}
                      required
                      onChangeAction={() => {
                        updateFormValue('name', '');
                        setActiveStep(0);
                      }}
                    />
                    {/* <AppInput id="roadName" label="Road Name" /> */}
                    <Box display="flex" flexDirection="row" gap={10} mt="16px">
                      <AppInput
                        id="startingMilepost"
                        label="Starting Milepost"
                        labelStyles={styles.inputLabel}
                        onClick={() => handleSetMilePost('edit')}
                        onChange={(e) => updateFormValue('startingMilepost', e.target.value)}
                        value={`POST 1 - ${form.startingMilepost}`}
                        autoComplete="off"
                      />
                      <AppInput
                        id="roadName"
                        label="Ending Milepost"
                        labelStyles={styles.inputLabel}
                        onClick={() => handleSetMilePost('edit')}
                        onChange={(e) => updateFormValue('endingMilepost', e.target.value)}
                        value={`POST 2 - ${form.endingMilepost}`}
                        autoComplete="off"
                      />
                    </Box>
                    {selectingMilePost && !onChangeRoad && (
                      <Box marginTop="var(--rem-16px)">
                        {/* <Box
                          pl="var(--rem-16px)"
                          py="var(--rem-10px)"
                          bgColor="#464647"
                          sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}
                        >
                          <MyIcon icon="my_location" style={{ color: '#979BA6', marginRight: '10px' }} />
                          <p
                            style={{
                              color: '#979BA6',
                              fontSize: 'var(--rem-16px)',
                              fontWeight: '500',
                              lineHeight: 'var(--rem-16px)',
                            }}
                          >
                            Selecting Endpoint
                          </p>
                        </Box> */}

                        <Box display="flex" justifyContent="flex-end">
                          <IconButton
                            title="Done"
                            aria-label="Done"
                            icon={<MyIcon icon="check" style={{ color: '#fff' }} />}
                            size="md"
                            onClick={() => handleSetMilePost('save')}
                            sx={{ background: APP_COLORS.blue, marginRight: 'var(--rem-12px)' }}
                          />
                          {/* <IconButton
                            title="Reset"
                            aria-label="Reset"
                            icon={<MyIcon icon="restart_alt" style={{ color: '#fff' }} />}
                            size="md"
                            onClick={() => handleSetMilePost(null)}
                            sx={{ background: '#464647', marginRight: 'var(--rem-12px)' }}
                          /> */}
                          <IconButton
                            title="Close"
                            aria-label="Close"
                            icon={<MyIcon icon="close" style={{ color: '#fff' }} />}
                            size="md"
                            onClick={() => handleSetMilePost('cancel')}
                            sx={{ background: '#464647' }}
                          />
                        </Box>
                      </Box>
                    )}
                    {/* <Checkbox
                        colorScheme="customBlue"
                        fontSize="14px"
                        fontWeight="500"
                        mt="16px"
                        // onChange={() => toggleRoadAssetView(AssetType.roadSign)}
                      >
                        <span style={{ color: 'var(--border-color-lighter)' }}>Select entire route</span>
                      </Checkbox> */}
                  </Box>
                )}

                {index == 1 && (
                  <Box mb="32px" width="100%">
                    <p style={styles.label}>Treatment Details</p>
                    <SearchableInput
                      list={treatments}
                      id="treatment"
                      label="Treatment"
                      required
                      value={form.treatment}
                      labelStyles={styles.inputLabel}
                      selectOptionAction={(value) => updateFormValue('treatment', value)}
                      onChangeAction={() => updateFormValue('treatment', '')}
                    />
                    <AppInput
                      id="cost"
                      label="Estimated Cost"
                      containerStyle={{ marginTop: '16px' }}
                      labelStyles={styles.inputLabel}
                      leftElement="$"
                      autoComplete="off"
                      required
                      onChange={(e) => updateFormValue('estimatedCost', e.target.value)}
                      value={form.estimatedCost}
                    />
                  </Box>
                )}

                {index == 2 && (
                  <Box mb="8px" width="100%">
                    <p style={styles.label}>Management</p>

                    <Box display="flex" flexDirection="row" gap={10} mt="16px">
                      <AppInput
                        id="plannedDate"
                        label="Planned Date"
                        labelSideText=" (Optional)"
                        labelSideTextStyles={{ color: '#979BA6' }}
                        type="date"
                        colorScheme="customBlue"
                        labelStyles={styles.inputLabel}
                        // defaultValue={new Date().toISOString().split('T')[0]}
                        onChange={(e) => updateFormValue('plannedDate', e.target.value)}
                        value={
                          form.plannedDate && form.plannedDate.length
                            ? new Date(form.plannedDate).toISOString().split('T')[0]
                            : ''
                        }
                      />
                      <Box w="100%">
                        <p style={{ marginBottom: '8px', ...styles.inputLabel }}>Category</p>
                        <Select
                          onChange={(e) => updateFormValue('category', e.target.value)}
                          mb="16px"
                          style={{ textOverflow: 'ellipsis', outline: 'none' }}
                          // defaultValue={JobCategory.backlog}
                          value={form.category}
                        >
                          <option value={JobCategory.backlog}>Backlog</option>
                          <option value={JobCategory.maintenance}>Maintenance</option>
                        </Select>
                      </Box>
                    </Box>

                    <Box w="100%">
                      <p style={{ marginBottom: '8px', ...styles.inputLabel }}>Status</p>
                      <Select
                        onChange={(e) => updateFormValue('status', e.target.value)}
                        mb="16px"
                        style={{ textOverflow: 'ellipsis', outline: 'none' }}
                        value={form.status}
                      >
                        <option value={JobStatus.not_started}>Not Started</option>
                        <option value={JobStatus.in_progress}>In Progress</option>
                        <option value={JobStatus.delayed}>Delayed</option>
                        <option value={JobStatus.completed}>Completed</option>
                      </Select>
                    </Box>

                    <SearchableListInput
                      list={companyUsers}
                      id="owner"
                      label="Owner"
                      required
                      value={form.ownerId}
                      labelStyles={styles.inputLabel}
                      selectOptionAction={(value) => updateFormValue('ownerId', value)}
                      onChangeAction={() => updateFormValue('ownerId', '')}
                    />

                    <Box display="flex" flexDirection="row" my="23px">
                      <Button marginRight="12px" onClick={onHandleClose} width="50%">
                        Cancel
                      </Button>
                      <Button
                        colorScheme="customBlue"
                        color="#fff"
                        onClick={!job ? handleCreateJob : handleUpdateJob}
                        isDisabled={activeStep != 3 || isSubmitting}
                        width="50%"
                      >
                        {!isSubmitting && (!job ? 'Create' : 'Save')}
                        {isSubmitting && (
                          <Spinner
                            width="25px"
                            height="25px"
                            thickness="3px"
                            speed="0.65s"
                            emptyColor="gray.200"
                            color="gray.500"
                            size="xl"
                          />
                        )}
                      </Button>
                    </Box>
                  </Box>
                )}

                <StepSeparator style={{ ...(index >= activeStep && { backgroundColor: 'var(--separator-color)' }) }} />
              </Step>
            ))}
          </Stepper>
        </Box>
        {/* )} */}
      </Box>
      {/* )} */}
    </>
  );
}

const styles: ComponentStyle = {
  container: {
    top: '112px',
    marginLeft: 'var(---view-padding-m)',
    marginRight: '0px',
    position: 'fixed',
    left: 0,
    borderRadius: '24px',
    transition: 'width 0.5s ease',
  },
  label: {
    fontSize: '20px',
    lineHeight: '28px',
    fontWeight: '500',
    marginBottom: '16px',
    marginTop: '2px',
  },
  radioLabel: {
    fontSize: '16px',
    lineHeight: '24px',
    fontWeight: '500',
  },
  inputLabel: {
    fontSize: '14px',
    lineHeight: '20px',
    fontWeight: '500',
  },
};
