import { Box, Spinner, Text } from '@chakra-ui/react';

export const FullScreenLoader = ({ loaderText = ' Please Wait' }: { loaderText: string }) => {
  return (
    <Box
      position="fixed"
      top={0}
      left={0}
      width="100vw"
      height="100vh"
      display="flex"
      alignItems="center"
      justifyContent="center"
      backgroundColor="rgba(0, 0, 0, 0.5)" // Semi-transparent gray background
      zIndex={9999}
    >
      <Box textAlign="center" color="white">
        <Spinner size="xl" thickness="4px" speed="0.65s" color="gray.300" />
        <Text fontSize="xl" mt={4}>
          {loaderText}
        </Text>
      </Box>
    </Box>
  );
};

export default FullScreenLoader;
