import { Box, Divider, Flex, IconButton, Text } from '@chakra-ui/react';
import { useState } from 'react';
import { LmmJobResponse } from '../../shared/entity.ts';
import { formatAsCurrencyAlt } from '../../utils/stringUtils.tsx';
import { StatusTag } from '../StatusTag.tsx';
import { MyIcon } from '../../shared/component/Icons.tsx';

export default function PlanCard({
  job,
  setJobInEdit,
  setJobToDelete,
}: {
  job: LmmJobResponse;
  setJobInEdit: (job: LmmJobResponse) => void;
  setJobToDelete: (job: LmmJobResponse) => void;
}) {
  const [hovered, setHovered] = useState(false);

  return (
    <Flex
      flexDirection="column"
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
      gap="10px"
      padding="14px 20px"
      border="1px solid #464647"
      borderRadius="8px"
      backgroundColor="#383839"
    >
      <Flex flexWrap="nowrap" fontWeight="bold" position="relative">
        <Flex position="absolute" gap="6px" zIndex="1" right="0" visibility={hovered ? 'visible' : 'hidden'}>
          <IconButton
            aria-label="Delete"
            size="sm"
            icon={<MyIcon icon="edit"></MyIcon>}
            onClick={() => setJobInEdit(job)}
          ></IconButton>
          <IconButton
            aria-label="Delete"
            colorScheme="red"
            size="sm"
            icon={<MyIcon icon="delete"></MyIcon>}
            onClick={() => setJobToDelete(job)}
          ></IconButton>
        </Flex>
        <Text noOfLines={1} fontSize="18px">
          {job.roadName}
        </Text>
        <Box
          flex="none"
          marginLeft="auto"
          border="1px solid #979ba626"
          borderRadius="50px"
          fontSize="14px"
          padding="4px 12px"
          color="#979ba6"
        >
          PCI: 50
        </Box>
      </Flex>
      <Flex flexWrap="nowrap" fontSize="14px">
        <Box>{job.treatmentName}</Box>
        <Box sx={{ marginLeft: 'auto' }}>{formatAsCurrencyAlt(job.estimatedCost)}</Box>
      </Flex>
      <Divider />
      <Flex flexWrap="nowrap">
        <Box sx={{ fontStyle: 'italic' }}>{job.ownerName}</Box>
        <Box style={{ marginLeft: 'auto' }}>
          <StatusTag status={job.status}></StatusTag>
        </Box>
      </Flex>
    </Flex>
  );
}
