import { useContext, useEffect, useState } from 'react';
import type { LmmImage } from '../shared/entity.ts';
import { Zoom } from '../shared/component/Zoom.tsx';
import * as s from './MapLmmImageBox.module.css';

export function MapLmmImageBox({ lmmImage }: { lmmImage: LmmImage }) {
  const [hiDefImage, setHiDefImage] = useState<string>(null);
  useEffect(() => {
    const controller = new AbortController();
    const signal = controller.signal;

    setHiDefImage(null);

    // eslint-disable-next-line prefer-const
    const timerId = setTimeout(() => {
      fetch(`/api/images/highres/${lmmImage.id}`, { signal })
        .then((response) => {
          if (response.ok) {
            response.json().then((result) => {
              setHiDefImage(result.data);
            });
          } else {
            throw new Error(`API request failed with status: ${response.status}`);
          }
        })
        .catch((error) => {
          console.error('Error fetching high-res image:', error);
        });
    }, 500);

    return () => {
      clearTimeout(timerId);
      controller.abort();
    };
  }, [lmmImage.id]);
  return hiDefImage || lmmImage.base64 ? (
    <Zoom
      src={`data:image/jpeg;base64,${hiDefImage || lmmImage.base64}`}
      alt={`${lmmImage.pci}`}
      options={{
        maxScale: 4,
        slider: true,
        zoomer: true,
      }}
    />
  ) : (
    <div className={s.loadingplaceholder} />
  );
}
