import { Component, ReactNode } from 'react';
import { Box, Button, Heading, Text, VStack, Container, Image } from '@chakra-ui/react';
import * as Sentry from '@sentry/react';
import { REACT_APP_ENVIRONMENT } from '../../server/shared/env_constant.ts';

interface ErrorBoundaryProps {
  children: ReactNode;
}

interface ErrorBoundaryState {
  hasError: boolean;
  error: Error | null;
  errorInfo: React.ErrorInfo | null;
}

class ErrorBoundary extends Component<ErrorBoundaryProps, ErrorBoundaryState> {
  constructor(props: ErrorBoundaryProps) {
    super(props);
    this.state = { hasError: false, error: null, errorInfo: null };
  }

  static getDerivedStateFromError(error: Error): ErrorBoundaryState {
    return { hasError: true, error, errorInfo: null };
  }

  override componentDidCatch(error: Error, errorInfo: React.ErrorInfo): void {
    this.setState({ errorInfo });
    console.error('ErrorBoundary caught an error', error, errorInfo);
    if (REACT_APP_ENVIRONMENT != 'local') {
      Sentry.captureException(error, { extra: errorInfo as never });
    }
  }

  handleReload = (): void => {
    window.location.reload();
  };

  override render() {
    if (this.state.hasError) {
      return (
        <Box display="flex" backgroundColor="#cad4dd" justifyContent="center" alignItems="center" height="100vh" p={4}>
          <Container maxW="md" bg="white" boxShadow="xl" rounded="lg" p={6} textAlign="center">
            <VStack spacing={4}>
              <Image src="/asset/rocket.png" alt="Error icon" boxSize="150px" />
              <Heading size="lg" color="#979BA6">
                {`Oops, it looks like you've run into an error.`}
              </Heading>
              <Text>An unexpected error has occurred. Please try again later.</Text>
              <Button colorScheme="blue" onClick={this.handleReload}>
                Reload Page
              </Button>
            </VStack>
          </Container>
        </Box>
      );
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
