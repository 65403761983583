import * as Sentry from '@sentry/react';
import { SENTRY_DNS } from '../utils/constants.ts';
import { REACT_APP_ENVIRONMENT } from '../../server/shared/env_constant.ts';

if (REACT_APP_ENVIRONMENT != 'local') {
  Sentry.init({
    dsn: SENTRY_DNS,
    integrations: [Sentry.browserTracingIntegration(), Sentry.replayIntegration()],
    tracesSampleRate: 1.0,
    environment: REACT_APP_ENVIRONMENT,
  });
}
