import { Box, Flex } from '@chakra-ui/react';
import { useMemo } from 'react';
import { LmmJobResponse } from '../../shared/entity.ts';
import PlanYear from './PlanYear.tsx';

interface JobYear {
  year: number;
  jobs: LmmJobResponse[];
}

const UNPLANNED_YEAR = 0;

function groupJobsByYear(jobs: LmmJobResponse[]): JobYear[] {
  const jobsByYear = new Map<number, LmmJobResponse[]>();
  for (const job of jobs) {
    if (!job.plannedDate) {
      if (!jobsByYear.has(UNPLANNED_YEAR)) {
        jobsByYear.set(UNPLANNED_YEAR, new Array<LmmJobResponse>());
      }
      jobsByYear.get(UNPLANNED_YEAR).push(job);
      continue;
    }
    const jobYear = new Date(job.plannedDate)?.getFullYear();
    if (!jobsByYear.has(jobYear)) {
      jobsByYear.set(jobYear, new Array<LmmJobResponse>());
    }
    jobsByYear.get(jobYear).push(job);
  }

  const allJobs = Array.from(jobsByYear.entries().map(([year, jobs]): JobYear => ({ year, jobs }))).sort(
    (a: JobYear, b: JobYear) => a.year - b.year
  );
  return allJobs;
}

function nameForYear(year: number) {
  if (year === UNPLANNED_YEAR) {
    return 'UNPLANNED';
  }
  return String(year);
}

export default function PlanTimeline({
  jobs,
  setJobInEdit,
  setJobToDelete,
}: {
  jobs: LmmJobResponse[];
  setJobInEdit: (job: LmmJobResponse) => void;
  setJobToDelete: (job: LmmJobResponse) => void;
}) {
  const jobsByYear = useMemo(() => groupJobsByYear(jobs), [jobs]);

  return (
    <Flex direction="row" wrap="nowrap" height="100%" gap="12px">
      {jobsByYear.map((jobYear: JobYear) => (
        <Box key={jobYear.year} maxHeight="100%">
          <PlanYear
            name={nameForYear(jobYear.year)}
            year={jobYear.year}
            jobs={jobYear.jobs}
            setJobInEdit={setJobInEdit}
            setJobToDelete={setJobToDelete}
          ></PlanYear>
        </Box>
      ))}
    </Flex>
  );
}
