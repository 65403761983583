import { Box, IconButton, Spinner, useToast } from '@chakra-ui/react';
import { useContext, useEffect, useState } from 'react';
import { LmmJobResponse } from '../../shared/entity.ts';
import {
  capitalizeEachWord,
  formatAsCurrency,
  formatAsCurrencyAlt,
  getYearDate,
  removeAllCommas,
  textIsNumeric,
} from '../../utils/stringUtils.tsx';
import { StatusTag } from '../StatusTag.tsx';
import EditIcon from '../../asset/icons/EditIcon.tsx';
import { MyIcon } from '../../shared/component/Icons.tsx';
import AppInput from '../../shared/component/AppInput.tsx';
import SearchableInput from '../../shared/component/SearchableInput.tsx';
import { GetCompanyUsers, GetTreaments } from '../../query/mapqueries.ts';
import SearchableListInput from '../../shared/component/SearchableListInput.tsx';
import { JobCategory, JobStatus } from '../../shared/const.ts';
import { InputItem } from '../../shared/hook/interface.ts';
import { GlobalContext } from '../../context/GlobalContext.tsx';
import DISPATCH_ACTIONS from '../../context/actions.ts';

export default function PlanJobRow({
  job,
  isFirst,
  isLast,
  category,
  refetchJobs,
}: {
  job: LmmJobResponse;
  isFirst?: boolean;
  isLast?: boolean;
  category?: JobCategory;
  refetchJobs?: () => void;
}) {
  const [jobInEdit, setJobInEdit] = useState<LmmJobResponse>(null);
  const { dispatch, state } = useContext(GlobalContext);

  const { data: treatments = [] } = GetTreaments();
  const { data: companyUsers = [] } = GetCompanyUsers();
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const toast = useToast();
  const onSaveJob = () => {
    const selectedTreatment = treatments.find((x) => x.name == form.treatment);
    if (!selectedTreatment) {
      toast({
        title: 'Please select a valid treatment',
        status: 'error',
      });
      return;
    }
    const body = {
      plannedDate: form.plannedDate,
      estimatedCost: parseFloat(removeAllCommas(form.estimatedCost)),
      roadId: job.roadId,
      treatmentId: selectedTreatment.id,
      status: form.status,
      category: job.category,
      startingMilepost: job.startingMilepost,
      endingMilepost: job.endingMilepost,
      ownerId: form.ownerId,
    };
    setIsSubmitting(true);
    fetch(`/api/jobs/${job.id}`, {
      body: JSON.stringify(body),
      method: 'put',
      headers: { 'content-type': 'application/json' },
    })
      .then((data) => {
        setIsSubmitting(false);
        toast({
          title: 'Job updated successfully',
          status: 'success',
        });
        // refetchJobs();
        const owner = companyUsers.find((x) => x.id == form.ownerId);
        dispatch({
          type: DISPATCH_ACTIONS.UPDATE_JOB_DATA,
          payload: { job: { ...job, ...body, treatmentName: form.treatment, ownerName: owner.name }, category },
        });
        dispatch({ type: DISPATCH_ACTIONS.SET_IS_UPDATING_PLAN_MAP_JOB, payload: false });
        setJobInEdit(null);
      })
      .catch((err) => {
        setIsSubmitting(false);
        toast({
          title: 'Ops, job update failed',
          status: 'error',
        });
      });
  };
  const [form, setForm] = useState({
    treatment: '',
    estimatedCost: '',
    plannedDate: '',
    status: JobStatus.not_started as string,
    ownerId: '',
  });
  useEffect(() => {
    if (job) {
      setForm({
        treatment: job.treatmentName,
        estimatedCost: formatAsCurrency(`${job.estimatedCost}`),
        plannedDate: job.plannedDate,
        status: job.status,
        ownerId: job.ownerId,
      });
    }
  }, [job, jobInEdit]);

  type FormKeys = 'treatment' | 'estimatedCost' | 'plannedDate' | 'status' | 'ownerId';

  const updateFormValue = (key: FormKeys, value: string | InputItem) => {
    const formCopy = { ...form };
    if (typeof value == 'string') {
      if (key == 'estimatedCost') {
        if (!textIsNumeric(value as string)) return;
        formCopy.estimatedCost = formatAsCurrency(value);
      } else {
        formCopy[key] = value;
      }
    } else if (key == 'ownerId') {
      formCopy.ownerId = companyUsers.find((x) => x.id == value.id)?.id;
    }

    setForm(formCopy);
  };
  return (
    <Box
      sx={{
        p: 'var(--rem-20px)',
        backgroundColor: '#979BA626',
        ...(isFirst && { borderTopRadius: 'var(--rem-8px)' }),
        ...(isLast && { borderBottomRadius: 'var(--rem-8px)' }),
        ...(!isFirst &&
          !isLast && {
            borderBottom: '1px solid rgba(151, 155, 166, 0.15)',
          }),
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignContent: 'center',
        }}
      >
        <p style={styles.roadName} title={capitalizeEachWord(job.roadName)}>
          {capitalizeEachWord(job.roadName)}
        </p>

        <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
          <StatusTag
            status={job.status}
            isEditing={Boolean(jobInEdit)}
            onChangeAction={(status) => updateFormValue('status', status)}
          />
          {!jobInEdit && (
            <IconButton
              aria-label="Edit Icon"
              isDisabled={state.isEditingPlanMapViewJob}
              onClick={() => {
                setJobInEdit(job);
                dispatch({ type: DISPATCH_ACTIONS.SET_IS_UPDATING_PLAN_MAP_JOB, payload: true });
              }}
              icon={<EditIcon />}
              sx={{ marginLeft: '10px', background: 'none' }}
            />
          )}
          {jobInEdit && (
            <>
              {isSubmitting ? (
                <Spinner
                  width="20px"
                  height="20px"
                  thickness="3px"
                  speed="0.65s"
                  emptyColor="gray.200"
                  color="gray.500"
                  size="xs"
                  marginLeft="10px"
                />
              ) : (
                <IconButton
                  aria-label="save job"
                  onClick={onSaveJob}
                  icon={<MyIcon icon="check" style={{ color: '#fff' }} />}
                  sx={{ background: 'none', ml: '5px' }}
                />
              )}
              <IconButton
                aria-label="cancel"
                onClick={() => {
                  setJobInEdit(null);
                  dispatch({ type: DISPATCH_ACTIONS.SET_IS_UPDATING_PLAN_MAP_JOB, payload: false });
                }}
                icon={<MyIcon icon="close" style={{ color: '#979BA6' }} />}
                sx={{ background: 'none' }}
              />
            </>
          )}
        </Box>
      </Box>
      {!jobInEdit && jobInEdit == null && (
        <Box>
          <Box sx={styles.keyValueBox}>
            <Box width="60%">
              <p style={styles.keyvalue} title={job.treatmentName}>
                <span style={styles.keyValueSpan}>Treatment:</span> {job.treatmentName}
              </p>
            </Box>

            <Box width="40%">
              <p style={styles.keyvalue} title={job.plannedDate}>
                <span style={styles.keyValueSpan}>Planned Date:</span>{' '}
                {job.plannedDate?.length ? getYearDate(job.plannedDate) : '-'}
              </p>
            </Box>
          </Box>
          <Box sx={styles.keyValueBox}>
            <Box width="60%">
              <p style={styles.keyvalue} title={`${job.estimatedCost}`}>
                <span style={styles.keyValueSpan}>Estimated Cost:</span> {formatAsCurrencyAlt(job.estimatedCost)}
              </p>
            </Box>

            <Box width="40%">
              <p style={styles.keyvalue} title={job.ownerName}>
                <span style={styles.keyValueSpan}>Owner:</span>
                {job.ownerName}
              </p>
            </Box>
          </Box>
        </Box>
      )}
      {jobInEdit && (
        <>
          <Box display="flex" flexDirection="row" gap={10} mt="16px">
            <SearchableInput
              list={treatments}
              id="treatment"
              label="Treatment"
              required
              noTopMargin
              value={form.treatment}
              labelStyles={styles.inputLabel}
              selectOptionAction={(value) => updateFormValue('treatment', value)}
              onChangeAction={() => updateFormValue('treatment', '')}
            />
            <AppInput
              id="plannedDate"
              label="Planned Date"
              type="date"
              colorScheme="customBlue"
              labelStyles={styles.inputLabel}
              onChange={(e) => updateFormValue('plannedDate', e.target.value)}
              value={
                form.plannedDate && form.plannedDate.length
                  ? new Date(form.plannedDate).toISOString().split('T')[0]
                  : ''
              }
            />
          </Box>
          <Box display="flex" flexDirection="row" gap={10} mt="16px">
            <AppInput
              id="cost"
              label="Estimated Cost"
              labelStyles={styles.inputLabel}
              leftElement="$"
              autoComplete="off"
              required
              onChange={(e) => updateFormValue('estimatedCost', e.target.value)}
              value={form.estimatedCost}
            />
            <SearchableListInput
              list={companyUsers}
              id="owner"
              label="Owner"
              required
              noTopMargin
              value={form.ownerId}
              labelStyles={styles.inputLabel}
              selectOptionAction={(value) => updateFormValue('ownerId', value)}
              onChangeAction={() => updateFormValue('ownerId', '')}
            />
          </Box>
        </>
      )}
    </Box>
  );
}

const styles = {
  roadName: {
    fontSize: 'var(--rem-20px)',
    fontWeight: '700',
    lineHeight: 'var(--rem-28px)',
    marginTop: '1%',
    color: '#fff',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
  },
  labelContainer: { width: '50%', marginBottom: 'var(--rem-8px)' },
  infoLabel: { color: 'var(--Color-Lighter-Grey)', fontSize: 'var(--rem-14px)' },
  infoValue: { color: '#fff', fontWeight: '500', marginLeft: 'var(--rem-7px)' },
  tableHead: { borderWidth: '0px' },
  tableRow: {
    fontSize: '14px',
    borderWidth: '0px',
  },
  sideBox: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  leftText: {
    marginLeft: '16px',
    fontSize: 'var(--rem-24px)',
    fontWeight: '500',
    marginRight: '24px',
  },
  topIconText: {
    fontSize: '14px',
    fontWeight: '500',
    color: 'var(--border-color-lighter)',
  },
  iconBox: {
    marginLeft: 'auto',
  },
  keyvalue: {
    fontSize: 'var(--rem-14px)',
    fontWeight: '500',
    lineHeight: 'var(--rem-20px)',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
  },
  keyValueSpan: { color: '#979BA6', fontWeight: '400' },
  keyValueBox: {
    marginTop: 'var(--rem-16px)',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    width: '100%',
  },
  label: {
    fontSize: '20px',
    lineHeight: '28px',
    fontWeight: '500',
    marginBottom: '16px',
    marginTop: '2px',
  },
  radioLabel: {
    fontSize: '16px',
    lineHeight: '24px',
    fontWeight: '500',
  },
  inputLabel: {
    fontSize: 'var(--rem-14px)',
    lineHeight: '20px',
    fontWeight: '500',
  },
};
