import { IconButton, useDisclosure } from '@chakra-ui/react';
import SettingsIcon from '../asset/icons/SettingsIcon.tsx';
import { SettingsModal } from '../shared/modals/SettingsModal.tsx';
import * as s from './SettingsMenu.module.css';

export function SettingsMenu() {
  const { isOpen, onClose, onToggle } = useDisclosure();

  return (
    <>
      <SettingsModal isOpen={isOpen} onClose={onClose} />
      <div className={s.menuicon}>
        <IconButton
          className={s.hamburgerIconBox}
          title="Menu"
          aria-label="Menu"
          icon={<SettingsIcon />}
          size="sm"
          onClick={onToggle}
        />
      </div>
    </>
  );
}
