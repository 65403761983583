import { useContext, useEffect, useMemo, useState } from 'react';
import { adjustCentermile } from '../../server/shared/number.helper.ts';
import { LmmSegment } from '../shared/entity.ts';
import { GlobalContext } from '../context/GlobalContext.tsx';
import DISPATCH_ACTIONS from '../context/actions.ts';
import * as s from './SegmentCenterline.module.css';

export function SegmentCenterline({ segments, cityId }: { segments: LmmSegment[]; cityId: string }) {
  const { dispatch } = useContext(GlobalContext);
  const [updateData, setUpdateData] = useState(null);
  const segmentLane = useMemo(() => {
    let centerline = 0;
    let lane = 0;
    for (const segment of segments) {
      centerline += segment.centerline;
      lane += segment.lane;
    }
    const output = {
      centerline: centerline || 0,
      lane: lane || 0,
    };
    setUpdateData(output);
    return output;
  }, [segments]);

  useEffect(() => {
    dispatch({ type: DISPATCH_ACTIONS.SET_CURRENTLINE_DATA, payload: updateData });
  }, [dispatch, updateData]);

  return (
    <div className={s.container}>
      <div className={s.centerlineBox}>
        <div className={s.centerlineText}>Centerline Miles</div>
        <div className={s.value}>{adjustCentermile(segmentLane.centerline)}</div>
      </div>

      {/* {cityId != '0a7e2db8-4e43-4f7e-afd0-d18b186178d7' && cityId != 'a969e398-cf29-4620-9714-688ecd87eb74' && (
        <>
          <div className={s.divider}></div>
          <div className={s.laneBox}>
            <div className={s.laneText}>Lane Miles</div>
            <div className={s.value}>{adjustCentermile(segmentLane.lane)}</div>
          </div>
        </>
      )} */}
    </div>
  );
}
