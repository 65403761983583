import {
  RangeSlider,
  RangeSliderTrack,
  RangeSliderFilledTrack,
  RangeSliderThumb,
  FormControl,
  FormLabel,
  IconButton,
  Popover,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverFooter,
  PopoverTrigger,
  Button,
  useDisclosure,
  Stack,
  Checkbox,
  Box,
} from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import { MyIconFilled } from '../shared/component/Icons.tsx';
import { AssetType, defaultPciRange } from '../shared/const.ts';
import { LmmImagesFilter } from '../shared/entity.ts';
import { ShowRoadAssetsFilter } from '../interface.tsx';
import * as s from './MapFilter.module.css';

export function MapFilter({
  lmmImagesFilter,
  setLmmImagesFilter,
  toggleRoadAssetView,
  filterisActive,
  onFilterMap,
  showFilter,
  action,
  setAction,
  showAssets,
  disabled,
}: {
  lmmImagesFilter: LmmImagesFilter;
  setLmmImagesFilter: (lmmImagesFilter: LmmImagesFilter) => void;
  toggleRoadAssetView: (type?: AssetType) => void;
  filterisActive: boolean;
  onFilterMap: (pci: [number, number]) => void;
  showFilter: ShowRoadAssetsFilter;
  action: string;
  setAction: (action: string) => void;
  showAssets: {
    roadSigns: boolean;
    guardrails: boolean;
    sidewalks: boolean;
    inlets: boolean;
  };
  disabled: boolean;
}) {
  const { onOpen, onClose, isOpen } = useDisclosure();

  const [pciRange, setPciRange] = useState(lmmImagesFilter.pci);

  const onPciChange = (range: [number, number]) => setPciRange(range);

  const apply = (pci = pciRange) => {
    onFilterMap(pci);
  };

  const reset = (event: any, imagesOnly = false) => {
    setPciRange(defaultPciRange);
    setLmmImagesFilter({ pci: defaultPciRange });
    if (!imagesOnly) {
      toggleRoadAssetView();
    }
    apply(defaultPciRange);
  };

  useEffect(() => {
    if (action == 'reset') {
      reset(null, true);
      setAction('');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [action]);

  return (
    <Popover isOpen={isOpen} onOpen={onOpen} onClose={onClose} closeOnBlur>
      <PopoverTrigger>
        <IconButton
          title="Open filter options"
          aria-label="Open filter options"
          icon={
            <MyIconFilled
              icon="filter_alt"
              style={{ color: filterisActive || pciRange[0] != 0 || pciRange[1] != 100 ? '#3888FF' : '#9aa0a6' }}
            />
          }
          size="sm"
          className="openMapSearch"
          isDisabled={disabled}
        />
      </PopoverTrigger>
      <PopoverContent p="32px" className={s.popoverContent} background="#232323" width="350px" borderRadius={24}>
        <PopoverCloseButton zIndex={10} fontSize="16px" top="35px" right="32px" />
        <PopoverBody padding={0}>
          <FormControl as="fieldset">
            <FormLabel as="legend" fontSize="16px" mb="16px" fontWeight={500}>
              Pavement Condition Index:
            </FormLabel>

            <div className={s.pciRange}>
              <div className={s.pciRangeNumber} style={{ marginRight: '24px' }}>
                {pciRange[0]}
              </div>
              <div style={{ width: '70%' }}>
                <RangeSlider onChange={onPciChange} onChangeEnd={apply} defaultValue={defaultPciRange} value={pciRange}>
                  <RangeSliderTrack>
                    <RangeSliderFilledTrack backgroundColor="#3888FF" />
                  </RangeSliderTrack>
                  <RangeSliderThumb index={0} />
                  <RangeSliderThumb index={1} />
                </RangeSlider>
              </div>

              <div className={s.pciRangeNumber} style={{ marginLeft: '24px' }}>
                {pciRange[1]}
              </div>
            </div>
          </FormControl>
        </PopoverBody>
        <PopoverFooter p="16px 0 0 0" borderTopWidth={showAssets.guardrails || showAssets.roadSigns ? '1px' : '0px'}>
          {(showAssets.guardrails || showAssets.roadSigns) && <div className={s.filterName}>Filter</div>}
          <Stack gap={3} direction="column">
            {showAssets.roadSigns && (
              <Checkbox
                colorScheme="customBlue"
                isChecked={showFilter.roadSign}
                fontSize="14px"
                fontWeight="500"
                onChange={() => toggleRoadAssetView(AssetType.roadSign)}
              >
                <span className={s.checkboxLabel}>Road Signs</span>
              </Checkbox>
            )}
            {showAssets.guardrails && (
              <Checkbox
                colorScheme="customBlue"
                isChecked={showFilter.guardrail}
                fontSize="14px"
                fontWeight="500"
                onChange={() => toggleRoadAssetView(AssetType.guardrail)}
              >
                <span className={s.checkboxLabel}>Guardrails</span>
              </Checkbox>
            )}
            {showAssets.sidewalks && (
              <Checkbox
                colorScheme="customBlue"
                isChecked={showFilter.sidewalk}
                fontSize="14px"
                fontWeight="500"
                onChange={() => toggleRoadAssetView(AssetType.sidewalk)}
              >
                <span className={s.checkboxLabel}>Sidewalks</span>
              </Checkbox>
            )}
            {showAssets.inlets && (
              <Checkbox
                colorScheme="customBlue"
                isChecked={showFilter.inlet}
                fontSize="14px"
                fontWeight="500"
                onChange={() => toggleRoadAssetView(AssetType.inlet)}
              >
                <span className={s.checkboxLabel}>Inlets</span>
              </Checkbox>
            )}
          </Stack>
          <Box sx={{ display: 'flex', flexDirection: 'row', gap: 2 }}>
            <Button width="100%" py="10px" mt="24px" onClick={(e) => reset(e)}>
              Clear All
            </Button>
            <Button colorScheme="customBlue" width="100%" py="10px" mt="24px" color="white" onClick={onClose}>
              Save
            </Button>
          </Box>
        </PopoverFooter>
      </PopoverContent>
    </Popover>
  );
}
