import { useState, ChangeEvent, useEffect, CSSProperties } from 'react';
import { Box, FormControl, FormLabel, Input, List, ListItem } from '@chakra-ui/react';
import { fuzzySearchListArray } from '../../utils/stringUtils.tsx';
import { InputItem } from '../hook/interface.ts';

const SearchableListInput = <T extends InputItem>({
  list,
  id,
  label,
  labelStyles,
  required,
  value,
  selectOptionAction,
  onChangeAction,
  readonly,
  noTopMargin,
}: {
  list: T[];
  id: string;
  label: string;
  value?: string;
  labelStyles?: CSSProperties;
  required?: boolean;
  readonly?: boolean;
  selectOptionAction?: (value: InputItem) => void;
  onChangeAction?: () => void;
  noTopMargin?: boolean;
}) => {
  const [query, setQuery] = useState<string>('');
  const [filteredData, setFilteredData] = useState<InputItem[]>([]);
  const [searchableData, setSearchableData] = useState<InputItem[]>([]);
  const [showOptions, setShowOptions] = useState<boolean>(false);
  const [valueIsSet, setValueIsSet] = useState<boolean>(false);

  useEffect(() => {
    const display = searchableData.find((x) => x.id == value);
    setQuery(display?.name);
  }, [searchableData, value]);
  const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    setValueIsSet(false);
    setShowOptions(true);
    if (onChangeAction) {
      onChangeAction();
    }
    const value = event.target.value;
    setQuery(value);
    if (value && value.length) {
      setFilteredData(fuzzySearchListArray(query, searchableData));
    } else {
      setFilteredData(searchableData);
    }
  };

  const onInputFocus = () => {
    setShowOptions(true);
    if (query && query.length) {
      setFilteredData(fuzzySearchListArray(query, searchableData));
    } else {
      setFilteredData(searchableData);
    }
  };

  const handleOptionClick = (e: any, selected: InputItem) => {
    e.preventDefault();
    setQuery(selected.name);
    setValueIsSet(true);
    setShowOptions(false);
    if (selectOptionAction) {
      selectOptionAction(selected);
    }
  };

  useEffect(() => {
    setSearchableData(list);
    setFilteredData(list);
  }, [list]);

  useEffect(() => {
    filteredData.sort((a, b) => a.name.toLowerCase().localeCompare(b.name.toLowerCase()));
  }, [filteredData]);

  return (
    <Box width="100%" margin="0 auto" mt={noTopMargin ? 0 : 4} position="relative">
      <FormControl>
        <FormLabel htmlFor={id} sx={labelStyles}>
          {label}
          {required && <span style={{ color: '#E76262' }}>{` *`}</span>}
        </FormLabel>
        <Input
          onChange={handleInputChange}
          id={id}
          readOnly={readonly}
          onFocus={onInputFocus}
          value={query}
          onBlur={() => setTimeout(() => setShowOptions(false), 150)}
          autoComplete="off"
          backgroundColor="#2c2c2c"
          border="1px solid #383839"
        />
      </FormControl>
      {showOptions && (
        <List
          mt={2}
          spacing={2}
          border="1px solid var(--border-color-lighter)"
          borderRadius="md"
          maxHeight="300px"
          overflowY="auto"
          position="absolute"
          width="100%"
          bg="#232323"
          zIndex={1}
        >
          {filteredData.map((item, index) => (
            <ListItem
              key={index}
              padding={2}
              _hover={{ backgroundColor: 'var(--border-color-lighter)', cursor: 'pointer' }}
              onMouseDown={(e) => handleOptionClick(e, item)}
            >
              {item.name}
            </ListItem>
          ))}
        </List>
      )}
    </Box>
  );
};

// const AppInput = ({ label, id, ...inputProps }: ReusableInputProps) => {
//     return (
//       <FormControl w="100%">
//         <FormLabel htmlFor={id}>{label}</FormLabel>
//         <Input outlineColor="var(--color--blue" id={id} {...inputProps} />
//       </FormControl>
//     );
//   };

export default SearchableListInput;
