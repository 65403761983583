import { useContext } from 'react';
import { MapLocation } from '../../map/MapLocation.tsx';
import { MapMenu } from '../../map/MapMenu.tsx';
import { MapSearch } from '../../map/MapSearch.tsx';
import { SegmentCenterline } from '../../map/SegmentCenterline.tsx';
import { SegmentPci } from '../../map/SegmentPci.tsx';
import { SettingsMenu } from '../../map/SettingsMenu.tsx';
import { GlobalContext } from '../../context/GlobalContext.tsx';
import { GetTenantMap } from '../../query/mapqueries.ts';
import { useMapRecenter } from '../hook/useMapRecenter.ts';
import { UseRecenterParams } from '../hook/interface.ts';
import { LmmSegment } from '../entity.ts';
import * as s from './styles/TopNavBar.module.css';

interface Params extends UseRecenterParams {
  segments?: LmmSegment[] | null;
  screen?: string;
}

export default function TopNavBar({ mapRef, markersRef, segments, screen }: Params) {
  const { state } = useContext(GlobalContext);
  const recenter = useMapRecenter({ mapRef, markersRef });
  const { data: map = {} } = GetTenantMap();
  return (
    <header className={s.mapTopBar}>
      <MapMenu screen={screen} />
      <MapLocation cityId={map.cityId} />
      <SegmentPci images={state.imagesInView} usePci={screen != 'Map'} screen={screen} />
      <SegmentCenterline cityId={map.cityId} segments={segments || [map]} />
      <MapSearch recenter={recenter} images={state.lmmImages} />
      <SettingsMenu />
    </header>
  );
}
