import { useQuery } from 'react-query';
import { useContext, useEffect, useState } from 'react';
import {
  Box,
  IconButton,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
  ModalHeader,
  Button,
  Spinner,
  Text,
  useToast,
  Flex,
} from '@chakra-ui/react';
import { DragDropContext, DropResult } from 'react-beautiful-dnd';

import TopNavBar from '../shared/component/TopNavBar.tsx';
import { useTitle } from '../shared/hook/useTitle.ts';
import { LmmImage, LmmJob, LmmJobResponse } from '../shared/entity.ts';
import { GlobalContext } from '../context/GlobalContext.tsx';
import DISPATCH_ACTIONS from '../context/actions.ts';
import { useScreenDimensions } from '../shared/hook/useScreenDimensions.ts';
import { JobCategory } from '../shared/const.ts';
import { capitalizeEachWord } from '../utils/stringUtils.tsx';
import { sumNumberArray } from '../utils/numberUtils.ts';
import { MyIcon } from '../shared/component/Icons.tsx';
import { TopPageActions } from './TopPageActions.tsx';
import PlanMapView from './PlanMapView.tsx';
import PlanTimeline from './components/PlanTimeline.tsx';
import PlanBacklog from './components/PlanBacklog.tsx';

export function Plan() {
  const { state, dispatch } = useContext(GlobalContext);
  const { height } = useScreenDimensions();
  const [estimateCost, setEstimateCost] = useState<{ maintenance: number; backlog: number }>({
    maintenance: 0,
    backlog: 0,
  });
  const [jobInEdit, setJobInEdit] = useState<LmmJobResponse>(null);
  const [jobToDelete, setJobToDelete] = useState<LmmJobResponse>(null);
  const [isDeleting, setIsDeleting] = useState<boolean>(false);
  const [shrinkPage, setShrinkPage] = useState<boolean>(false);
  const toast = useToast();
  useTitle('Plan');

  const { refetch: refetchImages } = useQuery<LmmImage[]>('/api/images?pci%5B0%5D=0&pci%5B1%5D=100', {
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    refetchOnMount: true,
    async onSuccess(newLmmImages) {
      dispatch({ type: DISPATCH_ACTIONS.SET_LMMIMAGES, payload: newLmmImages });
    },
  });

  useEffect(() => {
    const estimatedCostCopy = { ...estimateCost };
    if (state.backLogJobs.length) {
      const costs = state.backLogJobs.map((job) => job.estimatedCost);
      estimatedCostCopy.backlog = sumNumberArray(costs);
    }
    if (state.maintenanceJobs.length) {
      const costs = state.maintenanceJobs.map((job) => job.estimatedCost);
      estimatedCostCopy.maintenance = sumNumberArray(costs);
    }
    setEstimateCost(estimatedCostCopy);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.backLogJobs, state.maintenanceJobs]);

  const { refetch: refetchJobs } = useQuery<LmmJob[]>('/api/jobs', {
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    refetchOnMount: true,
    async onSuccess(jobs) {
      const backLogJobs = jobs.filter((j) => j.category == JobCategory.backlog);
      const maintenanceJobs = jobs.filter((j) => j.category == JobCategory.maintenance);
      dispatch({ type: DISPATCH_ACTIONS.SET_LMMJOBS, payload: { category: JobCategory.backlog, jobs: backLogJobs } });
      dispatch({
        type: DISPATCH_ACTIONS.SET_LMMJOBS,
        payload: { category: JobCategory.maintenance, jobs: maintenanceJobs },
      });
    },
  });

  const handleRefetchJobs = () => {
    refetchJobs();
  };

  const setMaintenanceJobs = (jobs: LmmJobResponse[], movedRow: LmmJobResponse) => {
    dispatch({ type: DISPATCH_ACTIONS.SET_LMMJOBS, payload: { category: JobCategory.maintenance, jobs } });
    const isExist = jobs.find((x) => x.id == movedRow.id);
    if (isExist) {
      updateJobStatus(movedRow, JobCategory.maintenance);
    }
  };

  const setBacklogJobs = (jobs: LmmJobResponse[], movedRow: LmmJobResponse) => {
    dispatch({ type: DISPATCH_ACTIONS.SET_LMMJOBS, payload: { category: JobCategory.backlog, jobs } });
    const isExist = jobs.find((x) => x.id == movedRow.id);
    if (isExist) {
      updateJobStatus(movedRow, JobCategory.backlog);
    }
  };

  const updateJobYear = (job: LmmJobResponse, year?: number) => {
    // Default to a null string if there is no year specified.
    let newDateString: string | null = null;
    if (year) {
      // If a year was specified, either update the existing year to the one specified or create
      // a new planned date in the specified year. Use Christmas Day (December 25) as the default day.
      let newDate: Date;
      if (job.plannedDate) {
        newDate = new Date(job.plannedDate);
        newDate.setFullYear(year);
      } else {
        newDate = new Date(year, /*monthIndex=*/ 11, /*day=*/ 25);
      }
      newDateString = newDate.toISOString().split('T')[0];
    }
    const body = {
      plannedDate: newDateString,
    };
    return fetch(`/api/jobs/${job.id}`, {
      body: JSON.stringify(body),
      method: 'put',
      headers: { 'content-type': 'application/json' },
    });
  };

  //Drag and dop logic
  const onDragEnd = async (result: DropResult) => {
    const { source, destination } = result;

    // If there's no destination (e.g., dropped outside the list), do nothing
    if (!destination) return;

    // If dropped in the same list in the same position, do nothing
    if (source.droppableId === destination.droppableId && source.index === destination.index) {
      return;
    }

    // Get the source list (which table it was dragged from)
    const sourceList = source.droppableId === 'backlog' ? state.backLogJobs : state.maintenanceJobs;
    const setSourceList = source.droppableId === 'backlog' ? setBacklogJobs : setMaintenanceJobs;

    // Get the destination list (which table it was dragged to)
    const destinationList = destination.droppableId === 'backlog' ? state.backLogJobs : state.maintenanceJobs;
    const setDestinationList = destination.droppableId === 'backlog' ? setBacklogJobs : setMaintenanceJobs;

    // Remove item from source list
    const sourceIndex = sourceList.findIndex((job) => job.id === result.draggableId);
    const [movedRow] = sourceList.splice(sourceIndex, 1);

    if (destination.droppableId.startsWith('year:')) {
      const newYear = Number(destination.droppableId.substring(5));
      await updateJobYear(movedRow, newYear ?? null);
    }

    // Add the item to the destination list
    destinationList.push(movedRow);

    // Update both lists
    setSourceList([...sourceList], movedRow);
    setDestinationList([...destinationList], movedRow);
  };

  const updateJobStatus = (job: LmmJobResponse, category: JobCategory) => {
    fetch(`/api/jobs/${job.id}`, {
      body: JSON.stringify({ category }),
      method: 'put',
      headers: { 'content-type': 'application/json' },
    })
      .then((data) => {
        refetchJobs();
      })
      .catch((err) => {});
  };

  const onClose = () => {
    setJobInEdit(null);
  };

  const handleRowClicked = (job: LmmJobResponse) => {
    setJobInEdit(job);
  };

  const onDeleteConfirm = (job: LmmJobResponse) => {
    setJobToDelete(job);
  };

  const handleDeleteJob = () => {
    setIsDeleting(true);
    fetch(`/api/jobs/${jobToDelete.id}`, {
      method: 'delete',
      headers: { 'content-type': 'application/json' },
    })
      .then((res) => {
        return res.json();
      })
      .then((res) => {
        if (res.error) {
          setIsDeleting(false);
          toast({
            title: 'Error!! Unable to delete job',
            status: 'error',
          });
          setJobToDelete(null);
        } else {
          setIsDeleting(false);
          toast({
            title: 'Job deleted succesfully',
            status: 'success',
          });
          handleRefetchJobs();
          setJobToDelete(null);
        }
      })
      .catch((err) => {
        setIsDeleting(false);
        console.error(err);
        toast({
          title: 'Oops! Something went wrong.',
          description:
            'An unexpected error occurred. Please try again later. If the problem persists, contact support.',
          status: 'error',
        });
      });
  };

  return (
    <Flex flexDirection="column" height="100vh" sx={{ mx: 'var(--rem-24px)' }}>
      <TopNavBar screen="Plan" />
      <Box flexGrow="0" flexShrink="0">
        <TopNavBar screen="Plan" />
        {!shrinkPage && (
          <TopPageActions
            refetchJobs={handleRefetchJobs}
            onClose={onClose}
            job={jobInEdit}
            onSwitchToMapView={() => setShrinkPage(true)}
          />
        )}
      </Box>
      {shrinkPage ? (
        <PlanMapView
          maintenanceJobs={state.maintenanceJobs}
          backLogJobs={state.backLogJobs}
          refetchJobs={refetchJobs}
          onSwitchToListView={() => setShrinkPage(false)}
        />
      ) : (
        <>
          <Modal
            blockScrollOnMount={false}
            isCentered
            isOpen={!!jobToDelete}
            onClose={() => setJobToDelete(null)}
            returnFocusOnClose={false}
            closeOnEsc={false}
          >
            <ModalOverlay sx={{ background: 'rgba(0, 0, 0, 0.50);' }} />
            <ModalContent
              bg="var(--bg-color)"
              sx={{
                width: '45%',
                minWidth: '400px',
                maxWidth: '450px',
                top: 'var(--rem-20px)',
                borderRadius: 'var(--rem-24px)',
              }}
            >
              <ModalBody px="32px" pt="32px">
                <ModalHeader mx="0px" px="0px" pt="0px">
                  <Box display="flex" flexDirection="row" justifyContent="space-between" alignItems="center">
                    <p style={{ fontSize: '24px', fontWeight: '700' }}>Delete Job?</p>
                    <IconButton
                      title="Open filter options"
                      aria-label="Open filter options"
                      icon={<MyIcon icon="close" style={{ color: '#979BA6' }} />}
                      size="md"
                      onClick={() => setJobToDelete(null)}
                      sx={{ background: 'none', '&:hover': { background: 'none' } }}
                    />
                  </Box>
                  <Box backgroundColor="var(--separator-color)" height="1px" width="100%" mt="16px" mb="8px"></Box>
                </ModalHeader>
                <Text fontSize="16px" fontWeight="600">
                  {`Are you sure you want to delete this job for ${capitalizeEachWord(jobToDelete?.roadName)}?`}
                </Text>
                <Text fontSize="16px" fontWeight="600">
                  You can’t undo this
                </Text>
                <Box display="flex" flexDirection="row" my="23px">
                  <Button marginRight="12px" onClick={() => setJobToDelete(null)} width="50%">
                    Cancel
                  </Button>
                  <Button
                    background="#E76262"
                    color="#fff"
                    onClick={handleDeleteJob}
                    isDisabled={isDeleting}
                    width="50%"
                  >
                    {!isDeleting && 'Delete'}
                    {isDeleting && (
                      <Spinner
                        width="25px"
                        height="25px"
                        thickness="3px"
                        speed="0.65s"
                        emptyColor="gray.200"
                        color="gray.500"
                        size="xl"
                      />
                    )}
                  </Button>
                </Box>
              </ModalBody>
            </ModalContent>
          </Modal>

          <DragDropContext onDragEnd={onDragEnd}>
            <Box flexGrow="1" flexShrink="1" overflowY="hidden" sx={{ my: 'var(--rem-12px)' }}>
              <PlanTimeline
                jobs={state.maintenanceJobs}
                setJobInEdit={setJobInEdit}
                setJobToDelete={setJobToDelete}
              ></PlanTimeline>
            </Box>
            <Box flexGrow="0" flexShrink="0" sx={{ my: 'var(--rem-12px)' }}>
              <Text fontSize="20px" fontWeight="500" marginLeft="16px" marginBottom="12px">
                Backlog
              </Text>
              <PlanBacklog
                setJobInEdit={setJobInEdit}
                setJobToDelete={setJobToDelete}
                jobs={state.backLogJobs}
              ></PlanBacklog>
            </Box>
          </DragDropContext>
        </>
      )}
    </Flex>
  );
}

const styles = {
  tableHeader: {
    fontSize: '14px',
    lineHeight: '24px',
    color: 'var(--border-color-lighter)',
    fontWeight: '600',
    border: 'none',
  },
  tableData: {
    color: '#ffffff',
    fontSize: '16px',
    lineHeight: '24px',
    border: 'none',
  },
  checkBox: {
    border: 'none',
  },
  tableRow: {
    backgroundColor: '#464647',
    height: '56px',
    '&:hover': {
      backgroundColor: '#5a5a5b',
      cursor: 'pointer',
    },
  },
  tableBody: {
    backgroundColor: '#464647',
    borderRadius: '8px',
  },
};
