import { useSnapshot } from 'valtio';
import { state } from '../state.ts';
import type { User } from '../entity.ts';

export function useUser() {
  const { user } = useSnapshot(state);

  const setUser = (user: User) => {
    state.user = user;
  };

  const clearUser = () => {
    state.user = null;
  };

  return {
    user,
    isAuthenticated: !!user,
    setUser,
    clearUser,
  };
}
