import {
  Box,
  IconButton,
  Popover,
  PopoverArrow,
  PopoverContent,
  PopoverTrigger,
  useDisclosure,
  useOutsideClick,
} from '@chakra-ui/react';
import { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { MyIcon } from '../shared/component/Icons.tsx';
import MapIcon from '../asset/icons/MapIcon.tsx';
import PlanIcon from '../asset/icons/PlanIcon.tsx';
import { ComponentStyle } from '../interface.tsx';
import * as s from './MapMenu.module.css';

type MenuOption = 'Map' | 'Plan' | 'Report' | 'Profile' | 'Settings';

interface MapMenuOption {
  readonly name: MenuOption;
  readonly icon: (color: string) => JSX.Element;
  readonly to: string;
}

export function MapMenu({ screen }: { screen: string }) {
  const ref = useRef();

  const { onOpen, onClose, isOpen } = useDisclosure();
  useOutsideClick({
    ref,
    handler: () => {
      onClose();
    },
  });
  const options = [
    { name: 'Map', icon: (color) => <MapIcon color={color} />, to: '/map' },
    { name: 'Plan', icon: (color) => <PlanIcon color={color} />, to: '/plan' },
    // { name: 'Report', icon: <ReportIcon />, to: '/metrics' },
    // { name: 'Profile', icon: <ProfileIcon />, to: '/account' },
    // { name: 'Settings', icon: <SettingsIcon />, to: '/settings' },
  ] satisfies MapMenuOption[];
  const [selectedMenu, setSelectedMenu] = useState(screen || 'Map');
  const navigate = useNavigate();

  const onClickMenuOption = (option: MapMenuOption) => {
    setSelectedMenu(option.name);
    onClose();
    navigate(option.to);
  };

  useEffect(() => {
    setSelectedMenu(screen);
  }, [screen]);

  return (
    <Popover isOpen={isOpen} onOpen={onOpen} onClose={onClose} closeOnBlur={true} closeOnEsc={true}>
      <PopoverTrigger>
        <IconButton
          className={s.hamburgerIconBox}
          title="Menu"
          aria-label="Menu"
          icon={<MyIcon icon="menu" />}
          size="sm"
        />
      </PopoverTrigger>
      <PopoverContent style={styles.content}>
        <PopoverArrow marginLeft="-20px" />
        {options.map((option) => (
          <Box
            ref={ref}
            onClick={() => onClickMenuOption(option)}
            key={option.name}
            role="presentation"
            sx={{
              ...styles.menuOption,
              backgroundColor: selectedMenu == option.name ? 'var(--color--blue)' : 'initial',
            }}
            _hover={{
              backgroundColor: selectedMenu == option.name ? 'var(--color--blue)' : '#3888ff34',
            }}
          >
            <span className="material-icons" color="#fff">
              {option.icon(selectedMenu == option.name ? '#fff' : 'rgb(151, 155, 166)')}
            </span>
            <p style={styles.optionName}>{option.name}</p>
          </Box>
        ))}
      </PopoverContent>
    </Popover>
  );
}

const styles: ComponentStyle = {
  content: {
    background: 'var(--bg-color)',
    left: 'var(---view-padding-m)',
    width: '140px',
  },
  menuOption: {
    display: 'flex',
    flexDirection: 'row',
    height: '48px',
    alignItems: 'center',
    padding: '0 var(---view-padding)',
    borderRadius: '6px',
    margin: '2px',
    cursor: 'pointer',
  },
  optionName: {
    marginLeft: '12px',
    fontWeight: '500',
    fontSize: '14px',
  },
};
