import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
  ModalCloseButton,
  Input,
  IconButton,
  useToast,
  Spinner,
} from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import type { LmmImage, LmmMap } from '../shared/entity.ts';
import { MyIcon } from '../shared/component/Icons.tsx';
import { formatDate } from '../../server/shared/date.helper.ts';
import { capitalizeEachWord } from '../utils/stringUtils.tsx';
import { useScreenDimensions } from '../shared/hook/useScreenDimensions.ts';
import { getCategoryKeyFromPci, getColorFromCategoryKey } from './mapHelper.ts';
import * as s from './MapLmmImageModal.module.css';
import { MapLmmImageBox } from './MapLmmImageBox.tsx';
export function MapLmmImageModal({
  lmmImage,
  loop,
  onSave,
  onClose,
  roadName,
  fetchImages,
}: {
  lmmImage: LmmImage;
  loop: (direction: 1 | -1) => void;
  onSave(lmmImage: LmmImage): void;
  onClose(): void;
  roadName: string;
  fetchImages(): void;
}) {
  const toast = useToast();
  const [isEditing, setIsEditing] = useState<boolean>();
  const [isSubmiting, setIsSubmiting] = useState<boolean>();
  const [editValue, setEditValue] = useState<any>(lmmImage?.pci);
  const categoryKey = getCategoryKeyFromPci(lmmImage.pci);
  const color = getColorFromCategoryKey(categoryKey);
  const { register, handleSubmit, setFocus, reset } = useForm<LmmImage, LmmImage, LmmImage>();
  const [isPciInputChanged, setIsPciInputChanged] = useState<boolean>();
  const [userNavTracker, setUserNavTracker] = useState<number>(0);

  const submit = handleSubmit((body) => {
    if (!isPciInputChanged || lmmImage.pci == body.pci) {
      toast({
        title: 'Change PCI value to update it.',
        status: 'error',
        duration: 3000,
      });
      return;
    }
    setIsSubmiting(true);
    fetch(`/api/images/${lmmImage.id}`, {
      body: JSON.stringify(body),
      method: 'put',
      headers: { 'content-type': 'application/json' },
    })
      .then(() => {
        onSave({ ...lmmImage, ...body });
        exitEditMode();
        setIsSubmiting(false);
        toast({
          title: 'PCI saved',
          status: 'success',
        });
      })
      .catch((err) => {
        console.error(err);
        reset();
        setIsSubmiting(false);
        toast({
          title: 'Ups, something unexpected ocurred.',
          status: 'error',
        });
      });
  });

  const cancel = () => {
    exitEditMode();
    reset();
  };

  const onKeyUp = (evt: any) => {
    if (evt.key === 'Escape') {
      cancel();
    } else {
      setIsPciInputChanged(true);
    }
  };

  const enterEditMode = () => {
    setEditValue(null);
    setIsEditing(true);
    setTimeout(() => setFocus('pci'), 0);
    setTimeout(() => {
      setEditValue(lmmImage.pci);
    }, 500);
  };

  useEffect(() => {
    setEditValue(lmmImage.pci);
  }, [lmmImage]);

  const exitEditMode = () => {
    setIsEditing(false);
    setIsPciInputChanged(false);
    setEditValue(null);
  };

  const { height } = useScreenDimensions();

  const userMovementAction = (direction: 1 | -1) => {
    loop(direction);
    setUserNavTracker(userNavTracker + direction);
    if (userNavTracker > 5 || userNavTracker < -5) {
      //TODO: monitor to not fetch  images that already exist
      setUserNavTracker(0);
      fetchImages();
    }
  };

  const getPciText = (pci: number) => {
    const mapDataStrng = localStorage.getItem('mapData');
    const mapData = JSON.parse(mapDataStrng) as LmmMap;
    if(mapData.tenantId == "432af8e8-6591-4f24-8e92-b0f86848d2f7") {
      if (pci == null) return '';
    if (pci < 25) return 'Very Poor';
    if (pci < 50) return 'Poor';
    if (pci < 75) return 'Fair';
    return 'Good';
    }else{
      if (pci == null) return '';
      if (pci < 61) return 'Poor';
      if (pci < 76) return 'Fair';
      if (pci < 91) return 'Good';
      return 'Excellent';
    }
  };

  return (
    <Modal blockScrollOnMount={false} isOpen onClose={onClose} returnFocusOnClose={false} closeOnEsc={false}>
      <ModalOverlay sx={{ background: 'rgba(0, 0, 0, 0.074)' }} />
      <ModalContent
        bg="var(--bg-color)"
        sx={{ width: '45%', maxWidth: '45%', height: `${height - 130}px`, top: '46px' }}
      >
        <ModalBody className={s.body}>
          <div className={s.header}>
            {roadName && <div className={s.roadname}>{capitalizeEachWord(roadName)}</div>}
            <div className={s.date}>{formatDate(lmmImage.createdAt)}</div>
            <ModalCloseButton className={s.close} />
          </div>

          <div style={{ position: 'relative', height: '87%' }}>
            <MapLmmImageBox lmmImage={lmmImage} />
            <button onClick={() => userMovementAction(-1)} className={s.prev}>
              ❮
            </button>
            <button onClick={() => userMovementAction(1)} className={s.next}>
              ❯
            </button>
          </div>

          <form onSubmit={submit}>
            <div className={s.pciWrapper}>
              <div>{lmmImage.assetType ? 'Stop Sign' : 'Pavement Condition'}&nbsp;</div>
              <div style={{ display: 'flex', alignItems: 'center', width: '100px' }}>
                {isEditing ? (
                  <div style={{ display: 'flex', alignItems: 'center', gap: '0.3rem' }}>
                    <Input
                      {...register('pci', { required: true, min: 0, max: 100 })}
                      type="number"
                      min={0}
                      max={100}
                      placeholder="enter value"
                      value={editValue}
                      onKeyUp={onKeyUp}
                      onChange={(e) => setEditValue(e.target.value)}
                      width="3.5rem"
                      size="sm"
                    />
                    {isSubmiting ? (
                      <Spinner
                        width="25px"
                        height="25px"
                        thickness="3px"
                        speed="0.65s"
                        emptyColor="gray.200"
                        color="gray.500"
                        size="xl"
                      />
                    ) : (
                      <IconButton
                        onClick={submit}
                        type="submit"
                        title="Save PCI"
                        aria-label="Save PCI"
                        icon={<MyIcon icon="check" />}
                        size="sm"
                      />
                    )}
                    <IconButton
                      onClick={cancel}
                      title="Cancel editing PCI"
                      aria-label="Cancel editing PCI"
                      icon={<MyIcon icon="close" />}
                      size="sm"
                    />
                  </div>
                ) : (
                  <span
                    // onClick={enterEditMode}
                    style={{ color: lmmImage.pci ? color : '#C0C0C0', textTransform: 'capitalize' }}
                    role="button"
                    tabIndex={-1}
                  >
                    {getPciText(lmmImage.pci) || '-'}
                  </span>
                )}
              </div>
            </div>
          </form>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}
